import React from "react"

import SEO from "src/components/seo"
import Layout from "src/components/layout"
import GradientsNew from "src/components/gradients-new"
import TopMenu from "src/components/top_menu"
import SubpageLayout from "src/components/subpage_layout"
import SubpageFirstScreen from "src/components/subpage_first_screen"
import PriceList from "src/components/prices_list"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import divisions from "src/content/divisions"

import subpageStyles from "src/css/subpage.module.css"

import socialmedia_page from "src/images/pages/socialmedia/social-media-management_0.png"
import socialmedia from "src/images/pages/socialmedia/socialmedia-new-www.jpg"
import { connect } from "react-redux"

const SocialMedia = ({isMobile}) => (
  <Layout>
    <SEO title="Opieka Social Media - Departament Reklamy" keywords={[`departament reklamy`, `elo`, `react`]} lang="pl"/>
    <GradientsNew activeGradient={3} iconBg={divisions.socialmedia.icon}/>
    {isMobile.isMobile ? (
      <MobileMenu homeBack={true}/>
    ) : (
      <TopMenu homeBack={true}/>
    )}

    <SubpageLayout>
      <SubpageFirstScreen content={{
        title: "OPIEKA SOCIAL MEDIA",
        sitemap: [
          { title: "Opis", id: "id" },
          { title: "Opieka Social Media", id: "opieka-social-media" },
          { title: "Cennik", id: "cennik" },
        ],
        slogan: "Liczy się zasięg",
        img: socialmedia_page,
        texts: {
          1: {
            title: "TRAFIMY DO TWOICH KLIENTÓW",
            desc: "Dysponujemy specjalnymi narzędziami aby znaleźć dla Ciebie klientów, którzy pozostaną przy Tobie na długo. " +
            "Jesteśmy świadomi, że w obecnych czasach marketing internetowy potrafi przynieśćnajwięsze korzyści.",
          },
          2: {
            title: "",
            desc: "",
          },
        },
      }}/>



      
      <div className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.halfimg_block].join(" ")} id="opieka-social-media">
        <div className={subpageStyles.image} style={{
          backgroundImage: "url(" + socialmedia + ")",
          backgroundPosition: "center",
          backgroundSize: "auto 100%"
        }}/>
        <div className={subpageStyles.content}>
          <div className={subpageStyles.title}>Opieka Social Media</div>
          <div className={subpageStyles.subtitle}>Materiały marketingowe</div>
          <div className={subpageStyles.subtitle}>Teksty copywriterskie</div>
          <div className={subpageStyles.subtitle}>Promowanie profilu</div>
          <div className={subpageStyles.subtitle}>Opieka stroną WWW</div>
          <div className={subpageStyles.subtitle}>Analiza rynku oraz dobór potencjalnego klienta</div>
          <div className={subpageStyles.subtitle}>Powiększenie zasięgów social media</div>
        </div>
      </div>

      <div className={[subpageStyles.subpage_block].join(" ")} style={{
        height: "20vh",
      }}/>
      <PriceList id="cennik" content={{
        items: [
          { title: "Opieka kontem Instagram", desc: "", price: "- zł", prefix: "od" },
          { title: "Opieka kontem Facebook", desc: "", price: "- zł", prefix: "od" },
        ],
        suggestions: [divisions.papers],
      }}/>
      {isMobile.isMobile ? null : (
        <Footer/>
      )}
    </SubpageLayout>
  </Layout>
)

export default connect(state => ({
  isMobile: state.isMobile,
}), null)(SocialMedia)
